import React, { useState, useEffect } from 'react';
import { flushSync } from 'react-dom';
import styled from 'styled-components';

import VideoGrid from './videoGrid';
import Button from './buttons';
import { ButtonText } from '../theme/typography';
import CenteredTextBlock from '../components/centeredTextBlock';
import { Section } from './page-elements';
import Spacer from './spacer';
//Styled Components
const VideoFiltersWrap = styled.div`
  position: sticky;
  top: 40px;
  z-index: 99;
  /* border: 1px solid red; */
`;

const VideoFilterToggle = styled.div`
  display: flex;
  flex-direction: row;
  background: var(--blue6);
  border-radius: 16.5px;
  width: 100%;
  max-width: 450px;
  justify-content: space-between;
  margin: 0 auto 32px auto;
  margin-bottom: 8px; //!TODO: industry tag messing
  position: relative;
  z-index: -1;
  box-shadow: var(--box-shadow-lg);
`;

const VideoFilter = styled(Button)`
  flex: 1;
  color: var(--blue1);
`;

const ToggleThumb = styled.div`
  --dx: ${({ filter }) => {
    if (filter == 'all') {
      return '0';
    } else if (filter == 'commercial') {
      return '100%';
    } else {
      return '200%';
    }
  }};
  --transition: transform 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
  --transition: transform 0.5s ease-in-out;
  border-radius: 16.5px;
  background: var(--accent);
  position: absolute;
  width: calc((100% / 3) + 0.5px);
  height: 100%;
  z-index: -1;
  transition: var(--transition);
  transform: translateX(var(--dx));
`;

const IndustryFilters = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
  flex-direction: row;
  width: 100%;
  max-width: 450px;
  gap: 16px;
  background: var(--blue6);
  border-radius: 16.5px;
  padding: 8px 16px;
  box-shadow: var(--box-shadow-lg);
  margin-bottom: 32px;
  > select {
    flex: 1;
  }
`;

const Select = styled.select`
  /* appearance: none; */
  border: 1px solid var(--blue5);
  border-radius: 8px;
  /* height: 24px; */
  padding: 9px 24px 7px 8px;
  font-family: Poppins;
  font-weight: var(--font-weight-semibold);
  font-size: var(--font-size-button);
  line-height: 24px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: var(--blue1);
  outline: none;
  background: var(--blue6);

  option {
    color: var(--blue1);
    background-color: var(--blue6);
  }
`;

const NoVideosTile = styled(Section)`
  background: var(--white);
  box-shadow: var(--box-shadow-lg);
  display: flex;
  border-radius: 8px;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  padding: 32px 32px;
`;

const FilterableVideoGrid = ({ videos, productRoutes, solutionRoutes }) => {
  const [filter, setFilter] = useState('all');
  const [industryFilter, setIndustryFilter] = useState('all');
  const [filteredVideos, setFilteredVideos] = useState(videos);

  const filterVideos = () => {
    let tempVideos = [...videos];

    //Filter by Type
    if (filter === 'all') {
      //do nothing
    } else if (filter === 'commercial') {
      tempVideos = tempVideos.filter((video) =>
        video.productTags.find(
          (productTag) =>
            productTag.slug.current === 'online-commercial' ||
            productTag.slug.current === 'tv-commercial',
        ),
      );
    } else if (filter === 'corporate') {
      tempVideos = tempVideos.filter((video) =>
        video.productTags.find(
          (productTag) => productTag.slug.current === 'corporate',
        ),
      );
    }

    //Filter by Industry
    if (industryFilter != 'all') {
      if (industryFilter === 'foodAndDrink') {
        tempVideos = tempVideos.filter((video) =>
          video.client.industryTags.find(
            (industryTag) => industryTag.slug.current === 'food-and-drink',
          ),
        );
      } else if (industryFilter === 'transport') {
        tempVideos = tempVideos.filter((video) =>
          video.client.industryTags.find(
            (industryTag) => industryTag.slug.current === 'transport',
          ),
        );
      } else if (industryFilter === 'education') {
        tempVideos = tempVideos.filter((video) =>
          video.client.industryTags.find(
            (industryTag) => industryTag.slug.current === 'education',
          ),
        );
      } else if (industryFilter === 'financialServices') {
        tempVideos = tempVideos.filter((video) =>
          video.client.industryTags.find(
            (industryTag) => industryTag.slug.current === 'financial-services',
          ),
        );
      }
    }

    //Update FilteredVideos in State
    setFilteredVideos(tempVideos);
  };

  //Update videos on filter update
  useEffect(() => {
    if (!document.startViewTransition) {
      filterVideos();
      return;
    }

    document.startViewTransition(() => {
      flushSync(() => {
        filterVideos();
      });
    });
  }, [filter, industryFilter]);

  return (
    <>
      <VideoFiltersWrap>
        <VideoFilterToggle>
          <ToggleThumb filter={filter} />
          <VideoFilter
            label='All'
            type={'link'}
            active={filter == 'all'}
            onClick={() => {
              window.scrollTo({ top: 0, behavior: 'smooth' });
              setFilter('all');
            }}
          />
          <VideoFilter
            label='Commercial'
            type={'link'}
            active={filter == 'commercial'}
            onClick={() => {
              window.scrollTo({ top: 0, behavior: 'smooth' });
              setFilter('commercial');
            }}
          />
          <VideoFilter
            label='Corporate'
            type={'link'}
            active={filter == 'corporate'}
            onClick={() => {
              window.scrollTo({ top: 0, behavior: 'smooth' });
              setFilter('corporate');
            }}
          />
        </VideoFilterToggle>
        <IndustryFilters>
          <ButtonText
            style={{
              textAlign: 'center',
              color: 'var(--blue2)',
              marginTop: '2px',
            }}
          >
            By Industry:
          </ButtonText>
          <Select
            onChange={(e) => {
              window.scrollTo({ top: 0, behavior: 'smooth' });
              setIndustryFilter(e.target.value);
            }}
            name='Industry Filter'
          >
            <option value='all'>All</option>
            <option value='foodAndDrink'>Food & Drink</option>
            <option value='transport'>Transport</option>
            <option value='education'>Education</option>
            <option value='financialServices'>Financial Services</option>
          </Select>
        </IndustryFilters>
      </VideoFiltersWrap>
      {filteredVideos.length >= 1 ? (
        <VideoGrid
          videos={filteredVideos}
          productRoutes={productRoutes}
          solutionRoutes={solutionRoutes}
        />
      ) : (
        <>
          <Spacer size={48} />
          <CenteredTextBlock
            data={{
              preHeader: 'Oops',
              title: `No Videos Found`,
              body: `We haven't uploaded any videos matching your filters, try adjusting them. Alternatively, get in touch and we'll dig through our archive of over 30 years.`,
              primaryCTA: true,
              primaryCTAData: {
                label: `Get in touch`,
                arrow: true,
                path: '/contact',
              },
              secondaryCTA: true,
              secondaryCTAData: {
                label: `Let's make something niche`,
                arrow: true,
                path: '/contact',
              },
            }}
          />
        </>
      )}
    </>
  );
};

export default FilterableVideoGrid;
