import React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';

// Component Imports
import SEO from '../components/seo';
import Container from '../components/container';
import { Section } from '../components/page-elements';
import CenteredHeaderBlock from '../components/centeredHeaderBlock';
import CTABlock from '../components/ctaBlock';
import BottomCut from '../components/bottomCut';
import FilterableVideoGrid from '../components/filterableVideoGrid';
import AnimatedStripe from '../components/animatedStripe';
import ScrollToTopButton from '../components/scrollToTopButton';

// Asset Imports
import Stripes09SVG from '../images/stripes/stripes09.inline.svg';
import Stripes10SVG from '../images/stripes/stripes10.inline.svg';
import Spacer from '../components/spacer';

// Function Imports
import shuffleArray from '../helpers/shuffleArray';
import Button from '../components/buttons';

// Styled Components
const HeaderStripes = styled(AnimatedStripe)`
  position: absolute;
  top: 25%;
  left: -35%;
  width: 80%;
  height: 150%;
  z-index: 1;
  pointer-events: none;
`;

const BodyStripes = styled(AnimatedStripe)`
  position: absolute;
  bottom: 40%;
  right: -15%;
  width: 65%;
  height: 30%;
  z-index: 1;
  pointer-events: none;
`;

const VideoGridWrap = styled.div`
  position: relative;
  z-index: 2;
  margin-bottom: ${({ theme }) => theme.spacing.xxxl};
  margin-top: -180px;

  @media (min-width: 1024px) {
    margin-top: -240px;
  }
`;

const PortfolioPage = ({ data }) => {
  const videos = shuffleArray(data.allSanityVideo.nodes);

  return (
    <>
      <SEO title='Portfolio' />
      <Helmet>
        <body className='dark-nav' />
      </Helmet>
      <ScrollToTopButton />
      <Section
        dark
        cuts='bottomCut'
        style={{ marginTop: 'var(--site-header-height)' }}
      >
        <HeaderStripes direction='left'>
          <Stripes09SVG />
        </HeaderStripes>
        <Container>
          <CenteredHeaderBlock data={data.sanityPortfolioPage.headerBlock} />
        </Container>
        <BottomCut light />
      </Section>
      <Section style={{ overflowX: 'clip' }} light cuts='topAndBottomCut'>
        <BodyStripes midPage threshold={0.3}>
          <Stripes10SVG />
        </BodyStripes>
        <Container wide>
          <VideoGridWrap>
            <FilterableVideoGrid
              videos={videos}
              productRoutes={data.allSanityProductPage.nodes}
              solutionRoutes={data.allSanityIndustryPage.nodes}
            />
          </VideoGridWrap>
        </Container>
        <Container>
          <CTABlock data={data.sanityPortfolioPage.ctaBlock} />
        </Container>
        <BottomCut dark />
      </Section>
    </>
  );
};

// GraphQL Query
export const portfolioPageQuery = graphql`
  {
    sanityPortfolioPage {
      headerBlock {
        ...SanityCenteredHeaderBlockFragment
      }
      ctaBlock {
        ...SanityCTABlockFragment
      }
    }
    allSanityVideo(filter: { rubberduckProduction: { eq: true } }) {
      nodes {
        ...SanityVideoFragment
        relatedVideos {
          ...SanityVideoFragment
        }
        id
      }
    }
    allSanityProductPage {
      nodes {
        productTag {
          slug {
            current
          }
        }
      }
    }
    allSanityIndustryPage {
      nodes {
        industryTag {
          slug {
            current
          }
        }
      }
    }
  }
`;

export default PortfolioPage;
