import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import throttle from '../helpers/throttle';

const IconBubble = styled.div`
  --size: 48px;
  z-index: 99;
  position: fixed;
  bottom: 6vh;
  right: 3vw;
  width: var(--size);
  height: var(--size);
  border-radius: 50%;
  background-color: ${({ bubbleColor }) =>
    bubbleColor ? bubbleColor : 'var(--blue5)'};
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);

  opacity: ${({ visible }) => (visible ? 1 : 0)};

  pointer-events: none;

  ${({ visible }) =>
    visible &&
    css`
      pointer-events: unset;
      cursor: pointer;

      &:hover {
        opacity: 0.7;
      }
    `}
`;

const IconSVG = styled.svg`
  width: 24px;
  height: 24px;
  padding-bottom: 2px;
  stroke: var(--swiper-navigation-icon-color);
  fill: none;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
`;

const ScrollToTopButton = ({ bubbleColor }) => {
  const [scrollPosition, setScrollPosition] = useState(0);

  const handleScroll = () => {
    const position = window.scrollY;
    setScrollPosition(position);
  };

  useEffect(() => {
    window.addEventListener('scroll', throttle(handleScroll, 500), {
      passive: true,
    });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <IconBubble
      bubbleColor={bubbleColor}
      visible={scrollPosition > 1500}
      onClick={() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        handleScroll();
      }}
    >
      <IconSVG>
        <use href={`/icons/feather-sprite.svg#chevron-up`} />
      </IconSVG>
    </IconBubble>
  );
};

export default ScrollToTopButton;
