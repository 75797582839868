import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import VideoThumb from './videoThumb';
const Videos = styled.ul`
  --flex-gap: 24px;
  --videos-per-row: 1;
  width: 100%;
  display: flex;
  flex-wrap: wrap;

  gap: var(--flex-gap);

  @media (min-width: 38em) {
    --videos-per-row: 2;
  }

  @media (min-width: 70em) {
    --videos-per-row: 3;
  }

  > * {
    flex-basis: calc(
      (100% - ((var(--videos-per-row) - 1) * var(--flex-gap))) /
        var(--videos-per-row)
    );
  }
`;

const VideoGrid = ({ videos, style, productRoutes, solutionRoutes }) => (
  <Videos style={style}>
    {videos.map((video, index) => {
      return (
        <VideoThumb
          key={index}
          video={video}
          productRoutes={productRoutes}
          solutionRoutes={solutionRoutes}
          style={{
            viewTransitionName: `video${video.id}`,
          }}
        />
      );
    })}
  </Videos>
);

VideoGrid.propTypes = {
  videos: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default VideoGrid;
