// Shuffle input array using Fisher-Yates-Durstenfeld shuffle
export default function throttle(callbackFn, limit) {
  let wait = false;
  return () => {
    if (!wait) {
      callbackFn.call();
      wait = true;
      setTimeout(() => {
        wait = false;
      }, limit);
    }
  };
}
