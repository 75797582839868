import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { GatsbyImage } from 'gatsby-plugin-image';

// Asset imports
import PlaySVG from '../images/icons/playIcon.inline.svg';

//Component Imports
import { PreHeader, P2, D3 } from '../theme/typography';
import BreathingPlayButton from './breathingPlayButton';
import VideoModal from './videoModal';

//Function Imports

// Styled Components
const Container = styled.li`
  /*
    N.B:  Width calculation for video grids done in videoGrid.js to keep this component reusable.
          This is here as a fallback in case Video Thumb is used individually
  */
  flex-basis: 100%;
  list-style: none;
`;

const PosterImage = styled(GatsbyImage)`
  width: 100%;
  height: 100%;
  object-fit: cover;

  @media (hover: hover) {
    transition: transform 0.4s ease;
  }

  > img {
    border-radius: var(--border-radius);
  }
`;

const VideoTileWrapper = styled.div`
  --border-radius: 5px;

  position: relative;
  border-radius: var(--border-radius);
  overflow: hidden;
  cursor: pointer;

  @media (hover: hover) {
    &:hover {
      ${PosterImage} {
        transform: scale(1.05);
      }
    }
  }
`;

const AspectRatio = styled.div`
  height: 0;
  /* padding-top: 56.25% would be 16:9 */
  padding-top: 65%;
  position: relative;
`;

const AspectRatioContents = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

const GradientDiv = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: linear-gradient(
    0deg,
    rgba(8, 39, 58, 0.9) 0%,
    rgba(255, 255, 255, 0) 65%
  );

  border-radius: var(--border-radius);
`;

const DescriptionContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;

  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  text-align: left;

  margin-left: 24px;
  margin-right: 24px;
  margin-bottom: 24px;

  @media (min-width: 27em) {
    margin-left: 32px;
    margin-right: 32px;
  }
`;

const ClientTitle = styled(PreHeader)`
  color: ${({ theme }) => theme.color.accent};
`;

const VideoTitle = styled(P2)`
  color: ${({ theme }) => theme.color.white};
`;

const ButtonWrap = styled.div`
  position: relative;
  width: 48px;
  height: 48px;
`;

const TemporaryOverlay = styled.div`
  position: absolute;
  inset: 0;
  display: grid;
  padding: 24px;
  z-index: 99;

  font-family: Poppins;
  font-weight: var(--font-weight-semibold);
  font-size: var(--font-size-button);
  line-height: 24px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: white;
  background: rgba(0, 0, 0, 0.8);
`;

const TempTagGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const VideoThumb = ({ video, style, productRoutes, solutionRoutes }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  return (
    <>
      <Container
        onClick={() => setIsModalOpen(true)}
        style={style}
        onMouseOver={() => setIsHovered(true)}
        onMouseOut={() => setIsHovered(false)}
      >
        <VideoTileWrapper>
          {/* <TemporaryOverlay>
            <TempTagGroup>
              <D3 style={{ color: 'var(--accent)' }}>Product Tags:</D3>
              {video.productTags.map((productTag) => {
                return <div>{productTag.slug.current}</div>;
              })}
            </TempTagGroup>
            <TempTagGroup>
              <D3 style={{ color: 'var(--accent)' }}>Industry Tags:</D3>
              {video.client.industryTags.map((industryTag) => {
                return <div>{industryTag.slug.current}</div>;
              })}
            </TempTagGroup>
            <TempTagGroup>
              {video.relatedVideos.length < 4 ? (
                <D3 style={{ color: 'red' }}>
                  {`Related Videos Found: ${video.relatedVideos.length}`}
                </D3>
              ) : (
                <D3 style={{ color: 'var(--accent)' }}>
                  {`Related Videos Found: ${video.relatedVideos.length}`}
                </D3>
              )}
            </TempTagGroup>
          </TemporaryOverlay> */}
          <AspectRatio>
            <AspectRatioContents>
              {video && (
                <PosterImage
                  image={video.thumbnail.asset.gatsbyImageData}
                  alt={`${video.title}`}
                />
              )}
              <GradientDiv />
              {video.client && (
                <DescriptionContainer>
                  <div>
                    <ClientTitle>
                      {video.client.company && `${video.client.company}`}
                    </ClientTitle>
                    <VideoTitle>{video.title && `${video.title}`}</VideoTitle>
                  </div>
                  <ButtonWrap>
                    <BreathingPlayButton size={32} animating={isHovered} />
                  </ButtonWrap>
                </DescriptionContainer>
              )}
            </AspectRatioContents>
          </AspectRatio>
        </VideoTileWrapper>
        <VideoModal
          isModalOpen={isModalOpen}
          video={video}
          productRoutes={productRoutes}
          solutionRoutes={solutionRoutes}
          onClose={() => setIsModalOpen(false)}
        />
      </Container>
    </>
  );
};

VideoThumb.propTypes = {
  video: PropTypes.object.isRequired,
};

export default VideoThumb;
